import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';

import { GET_HOSPITAL_STUDIES_TO_LIST } from '../../graphql/queries';
import { ResponsiveListWrapperUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import { StudiesGridView } from '../Studies/StudiesGridView';
import { DATE_FORMAT } from '../../utils/functions';
import { StudiesFilterView } from '../Studies/StudiesFilterView';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const sortData = {
  default: { field: 'CREATED_AT', direction: 'DESC' },
};

const { doctorApp, patientApp } = useApplicationInterface();

const attributes = [
  { key: 'patientId', label: 'patient.id', width: 100, filter: doctorApp },
  { key: 'patientName', label: 'patient.name', width: 125, filter: doctorApp },
  // { key: 'title', label: 'study.title', width: 175, filter: true },
  { key: 'createdAt', label: 'date', width: 75, format: DATE_FORMAT, align: 'center' },
  { key: 'modality', label: 'modality', width: 50, filter: true, align: 'center' },
  { key: 'numberOfSeries', label: 'series', width: 50, type: 'number', sortable: false, align: 'center' },
  { key: 'numberOfInstances', label: 'instances', width: 50, type: 'number', sortable: false, align: 'center' },
  { key: 'studyInstanceUid', label: 'ID', width: 150, hidden: true },
  { key: 'description', label: 'description', width: 225, filter: true },
];

export const DashboardStudiesView = () => {
  const { t } = useTranslation();
  const hospital = useSelector((state) => state.hospital);
  const user = useSelector((state) => state.userInterface.user);
  const studiesView = useSelector((state) => state.userInterface.studiesView);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const filterElementRef = useRef();
  const [cursor, setCursor] = useState(null);
  const [studiesPerPage, setStudiesPerPage] = useState(25);

  const queryFilters = {};
  attributes
    .filter((field) => field.filter)
    .forEach((filter) => { queryFilters[filter.key] = filters[filter.key]; });

  const queryFirst = studiesView !== 'grid' ? 50 : studiesPerPage;

  const [getStudies, { loading, data, error }] = useLazyQuery(
    GET_HOSPITAL_STUDIES_TO_LIST,
    {
      variables: {
        hospitalUuid: hospital.uuid,
        ...(patientApp && { patientUuid: user.patientUuid }),
        first: queryFirst,
        cursor,
        createdAtAfter: moment(filters.fromDate).startOf('day ').format('YYYY-MM-DD HH:mm:ss'),
        createdAtBefore: moment(filters.untilDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ...(Object.keys(queryFilters).length && { filters: queryFilters }),
        orderBy: {
          field: sortData.default.field,
          direction: sortData.default.direction,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => getStudies(), [filters]);

  const handleSelected = () => {};

  const handleGoto = (dicomStudy) => {
    navigate(`/study/${dicomStudy.uuid}`);
  };

  const handleFilters = (selection) => {
    setCursor(null);
    setSelected([]);
    setFilters(selection);
  };

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  const showStudyList = filters && (filters.patientId || filters.patientName || filters.title);

  return (
    <ResponsiveListWrapperUI>
      {filters && (
        <StudiesFilterView
          attributes={attributes}
          filters={filters}
          viewFilters
          queryFilters={queryFilters}
          handleFilters={handleFilters}
          reference={filterElementRef}
          dark={false}
        />
      )}
      {showStudyList && (
        <StudiesGridView
          attributes={attributes}
          items={data && data.dicomStudies}
          loading={loading}
          selected={selected}
          handleSelected={handleSelected}
          handleAction={handleGoto}
          filterElement={filterElementRef && filterElementRef.current}
          rowsPerPage={studiesPerPage}
          setRowsPerPage={setStudiesPerPage}
          setCursor={setCursor}
          dark={false}
        />
      )}
    </ResponsiveListWrapperUI>
  );
};
